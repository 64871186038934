<template>
  <div class="section-content section-sky-objects">
    <div class="container-fluid">
      <div
        class="heading mb-4 d-flex align-items-center justify-content-between"
      >
        <div>
          <h2 class="font-semi-bold">Create Sky Object Page</h2>
          <p class="subtitle">Create new sky object.</p>
        </div>

        <a @click.prevent="$router.back()" class="icon font-semi-bold">
          <svg
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2"
          >
            <path
              d="M1.4793 6.65259H16.2606C16.4567 6.65259 16.6446 6.73045 16.7832 6.86906C16.9218 7.00766 16.9997 7.19564 16.9997 7.39165C16.9997 7.58767 16.9218 7.77565 16.7832 7.91425C16.6446 8.05286 16.4567 8.13072 16.2606 8.13072H1.4793C1.28329 8.13072 1.0953 8.05286 0.956702 7.91425C0.8181 7.77565 0.740234 7.58767 0.740234 7.39165C0.740234 7.19564 0.8181 7.00766 0.956702 6.86906C1.0953 6.73045 1.28329 6.65259 1.4793 6.65259Z"
              fill="#101010"
            />
            <path
              d="M1.78543 7.3916L7.91525 13.5199C8.05403 13.6587 8.13199 13.8469 8.13199 14.0432C8.13199 14.2395 8.05403 14.4277 7.91525 14.5665C7.77647 14.7052 7.58825 14.7832 7.39199 14.7832C7.19573 14.7832 7.00751 14.7052 6.86873 14.5665L0.217129 7.91486C0.148302 7.84621 0.0936956 7.76465 0.0564371 7.67486C0.0191786 7.58507 0 7.48881 0 7.3916C0 7.29439 0.0191786 7.19813 0.0564371 7.10834C0.0936956 7.01855 0.148302 6.937 0.217129 6.86834L6.86873 0.216741C7.00751 0.0779639 7.19573 0 7.39199 0C7.58825 0 7.77647 0.0779639 7.91525 0.216741C8.05403 0.355518 8.13199 0.54374 8.13199 0.74C8.13199 0.936261 8.05403 1.12448 7.91525 1.26326L1.78543 7.3916Z"
              fill="#101010"
            />
          </svg>

          Back
        </a>
      </div>

      <div class="content">
        <div class="row mb-3">
          <div class="col">
            <div class="card card-form border-0">
              <div class="card-body">
                <form @submit.prevent="save">
                  <div class="row no-gutters">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="name_en">Name English</label>
                        <input
                          v-model="form.name_en"
                          type="text"
                          class="form-control"
                          id="name_en"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="name_id">Name Indonesia</label>
                        <input
                          v-model="form.name_id"
                          type="text"
                          class="form-control"
                          id="name_id"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="description_en">Description English</label>
                        <input
                          v-model="form.description_en"
                          type="text"
                          class="form-control"
                          id="description_en"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="description_id"
                          >Description Indonesia</label
                        >
                        <input
                          v-model="form.description_id"
                          type="text"
                          class="form-control"
                          id="description_id"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="formed_en">Formed English</label>
                        <input
                          v-model="form.formed_en"
                          type="text"
                          class="form-control"
                          id="formed_en"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="formed_id">Formed Indonesia</label>
                        <input
                          v-model="form.formed_id"
                          type="text"
                          class="form-control"
                          id="formed_id"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="rotation_en">Rotation</label>
                        <input
                          v-model="form.rotation_en"
                          type="text"
                          class="form-control"
                          id="rotation_en"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="satelite">Satelite</label>
                        <input
                          v-model="form.satelite"
                          type="number"
                          min="0"
                          class="form-control"
                          id="satelite"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="additional_view">Addtional View</label>
                        <input
                          v-model="form.additional_view"
                          type="number"
                          min="0"
                          class="form-control"
                          id="additional_view"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label for="min_distance">Min Distance</label>
                        <input
                          v-model="form.min_distance"
                          type="number"
                          min="0"
                          class="form-control"
                          id="min_distance"
                          required
                        />
                      </div>
                    </div>

                    <div class="col-md-12 d-flex justify-content-end mt-3">
                      <button
                        v-if="!isSubmit"
                        type="submit"
                        class="btn btn-grey px-4 py-2"
                      >
                        Save Data
                      </button>
                      <button
                        v-else
                        class="
                          btn btn-grey
                          px-4
                          py-2
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                        disabled
                      >
                        <b-spinner class="mx-3" height="1rem"></b-spinner>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Services
import { toastedOption, capitalize } from "@/services/general.js";
import { createSkyObject } from "@/services/admin/SkyObjects.js";

export default {
  name: "AdminSkyObjectsCreate",
  metaInfo: {
    title: "Admin Sky Objects - Space Walk",
    description:
      "Space Walk is a website that is intended for people who have interests and talents in astronomy, with some basic knowledge, it is hoped that many people will start to be interested in astronomy.",
  },
  data() {
    return {
      isSubmit: false,
      form: {
        name_en: "",
        name_id: "",
        description_en: "",
        description_id: "",
        formed_en: "",
        formed_id: "",
        rotation_en: "",
        satelite: 0,
        additional_view: 0,
        min_distance: 0,
      },
    };
  },
  methods: {
    save() {
      this.isSubmit = true;

      createSkyObject(this.form)
        .then(() => {
          this.$toasted.success("Create success", toastedOption);
          this.$router.back();
        })
        .catch((error) => {
          this.isSubmit = false;

          error.response.data.body.errors.forEach((message) => {
            this.$toasted.error(capitalize(message.msg), toastedOption);
          });
        });
    },
  },
};
</script>